import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/detail-design";

// =====|  Dashboard Service  |=====

const AuroraService = {
	requestDetailDesign: (data: any) => http.post(`${apiEndpoint}/request`, data, { headers: getAuthHeader() }),
	getUserDetailDesign: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const requestDetailDesign = (data: any) => {
	return AuroraService.requestDetailDesign(data);
};
export const getUserDetailDesign = () => {
	return AuroraService.getUserDetailDesign();
};
