import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/welcome-email";

const WelcomeEmail = {
	sendWelcomeEmailToCustomer: (data: { userId: string }) =>
		http.post(`${apiEndpoint}`, data, {
			headers: { "Content-Type": "application/json", ...getAuthHeader() },
		}),
};

// =====|  APIs  |=====

export const sendWelcomeEmailToCustomer = (data: { userId: string }) => {
	return WelcomeEmail.sendWelcomeEmailToCustomer(data);
};
