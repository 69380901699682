import { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { toast } from "react-toastify";
import PageLayout from "../PageLayout/PageLayout";
import { allRoutes } from "../../Routes/AllRoutes";
import { getAllAssignedCustomers, getAllUnassignedCustomers, getAllUnassignedCustomersOfAdminManagerAndPsl } from "../../Services/dashboardService";
import TableBlock from "../Common/Table/TableBlock";
import { customerTableHeaders } from "../Installers/Representatives/RepresentativeDetails";
import { useNavigate } from "react-router-dom";
import AssignCompanyDialog from "../Installers/AssignCompanyDialog";
import {
	isAdminManagerLoggedIn,
	isDirectorLoggedIn,
	isPslLoggedIn,
	isSuperAdminLoggedIn,
} from "../../Services/userService";
import CustomTableOptions from "../Common/CustomTableOptions";
import CustomButton from "../Common/CustomButton";
import { Download } from "@mui/icons-material";
import { downloadCustomersCsv, downloadCustomersProposalCsv } from "../../Services/downloadCustomersCsvService";
import { is } from "date-fns/locale";
import AssignRepresentativeToCustomerAdminManagerDialog from "../Installers/AssignRepresentativeToCustomerAdminManagerDialog";
import { sendWelcomeEmailToCustomer } from "../../Services/welcomeEmailService";

const Customers = () => {
	const navigate = useNavigate();
	const isPsl = isPslLoggedIn();
	const isAdminManager = isAdminManagerLoggedIn();
	const [loading, setLoading] = useState<boolean>(false);
	const [manager, isManager] = useState<boolean>(false);
	const [data, setData] = useState<{ assigned: Array<any>; unassigned: Array<any> }>({
		assigned: [],
		unassigned: [],
	});
	const [assigningCustomer, setAssigningCustomer] = useState<{
		text: string;
		id: string;
		type?: "representative" | "manager";
	} | null>(null);


	useEffect(() => {
		getAllUsers(true);
	}, []);

	const sendWelcomeEmail = (userId: string) => async () => {
		try {
			const payload = {
				userId
			};
			const welcomeEmail = await sendWelcomeEmailToCustomer(payload);
			toast.success(welcomeEmail?.data?.welcomeEmail?.message);
		} catch (error: any) {
			toast.error(error);
		}
	}

	const getAllUsers = async (displayLoader: boolean) => {
		setLoading(displayLoader);
		try {
			// const { data: assigned } = await getCustomersWithCurrentSteps();
			const { data: assigned } = await getAllAssignedCustomers();
			console.log("Assigned: ", assigned);

			const { data: unassigned } = await getAllUnassignedCustomers();
			console.log("Un Assigned: ", unassigned);

			if (isPslLoggedIn() || isAdminManagerLoggedIn()) {
				const { data: assigned } = await getAllAssignedCustomers();
				const { data: unassigned } = await getAllUnassignedCustomersOfAdminManagerAndPsl();
				setData({ assigned: assigned || [], unassigned: unassigned || [] });
			}
			else {

				setData({ assigned: assigned || [], unassigned: unassigned || [] });
			}
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const openDialog = (props: { text: string; id: string; type?: "representative" | "manager" }) => {
		setAssigningCustomer(props);
	};


	const closeDialog = () => setAssigningCustomer(null);

	const unassignedCustomersTableHeader = [
		...customerTableHeaders.filter((item) => item.text !== "Report"),
		{
			text: "",
			key: "name",
			align: "right",
			notClickable: true,
			customComponent: (props: { id: string; text: string }) => (
				<CustomTableOptions
					menuOptions={[
						{
							text: "Assign Representative",
							onClick: () => {
								openDialog({ ...props, type: "representative" });
							},
						},
						{
							text: "Assign Manager",
							onClick: () => {
								openDialog({ ...props, type: "manager" });
							},
						},
					]}
				/>
			),
		},
	];

	const assignedCustomersTableHeader = [
		...customerTableHeaders.slice(0, 4),
		{ text: "Installer Company", key: "company", sortable: true, showEllipses: true, maxWidth: 130 },
		...customerTableHeaders.slice(4).filter((item) => item.text !== "Report"),
		{
			text: "Welcome Email",
			key: "welcomeEmail",
			sortable: true,
			showEllipses: true,
			maxWidth: 130,
			customComponent: (props: any) => (
				<CustomButton sx={{ py: 8, px: 14 }} variant="outlined" onClick={(event) => {
					event.stopPropagation(); // Stop the event from propagating to the row
					sendWelcomeEmail(props.id)();
				}}>
					Send Welcome Email
				</CustomButton>
			),
		},
		{
			text: "",
			key: "name",
			align: "right",
			notClickable: true,
			customComponent: (props: any) => (
				<CustomTableOptions
					menuOptions={[
						{
							text: "View Logs",
							onClick: () => {
								navigate(allRoutes.LOGS.replace(":id", props.sequentialId));
							},
						},

						{
							text: "Change Status",
							onClick: () => {
								navigate(allRoutes.UPDATE_STATUS.replace(":id", props.sequentialId));
							},
						},
						{
							text: "Download Summary Proposal CSV",
							onClick: () => {
								console.log(props)
								handleDownload(true, props.id, props.text)
							},
						},
					]}
				/>
			),
		},
	];

	const handleDownload = async (isDownloadProposal = false, userId?: string, userName?: string) => {
		try {
			const fileName = isDownloadProposal ? `${userName}'s Summary Proposal.csv` : 'Customers.csv'
			const userIdentifier = userId || '';
			const response = isDownloadProposal
				? await downloadCustomersProposalCsv(userIdentifier) : await downloadCustomersCsv()


			// Create a URL for the blob
			const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName); // Set the filename
			document.body.appendChild(link);
			link.click();

			// Clean up
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error('Error downloading CSV:', error);
		}
	};


	return (
		<PageLayout loading={loading} hideBackButton>
			{(isAdminManagerLoggedIn() || isPslLoggedIn()) && (

				<>

					<TableBlock
						addButtonText={isAdminManagerLoggedIn() || isPslLoggedIn() ? "Add Customer" : undefined}
						addButtonPath={isAdminManagerLoggedIn() || isPslLoggedIn() ? allRoutes.ADD_CUSTOMER : undefined}
						heading="Unassigned Customers"
						subHeading="These are all the unassigned customers:"
						tableData={data.unassigned}
						tableHeaders={unassignedCustomersTableHeader}
						emptyStateMessage="There are no unassigned customers available"
						detailsPagePath={allRoutes.VIEW_CUSTOMER}
					/>

					<Divider sx={{ my: 16 }} />
				</>
			)}

			<TableBlock
				heading="Customers"
				subHeading="These are all the customers with assigned company:"
				tableData={data.assigned}
				tableHeaders={assignedCustomersTableHeader}
				emptyStateMessage="There are no customers present. Please add a customer"
				filterByCompany={!(isPsl || isAdminManager)}
				detailsPagePath={allRoutes.VIEW_CUSTOMER}
				isCsv
				onDownloadClick={() => handleDownload(false)}
				isAddCustomer
				onAddCustomer={() => navigate(allRoutes.ADD_CUSTOMER)}
			/>

			{
				assigningCustomer?.type === "representative" && (
					<AssignRepresentativeToCustomerAdminManagerDialog
						open={!!assigningCustomer}
						activeUser={assigningCustomer}
						isManager={false}
						onClose={closeDialog}
						onSuccess={() => getAllUsers(false)}
					/>
				)
			}

			{
				assigningCustomer?.type === "manager" && (
					<AssignRepresentativeToCustomerAdminManagerDialog
						open={!!assigningCustomer}
						activeUser={assigningCustomer}
						isManager={true}
						onClose={closeDialog}
						onSuccess={() => getAllUsers(false)}

					/>
				)
			}


		</PageLayout>
	);
};

export default Customers;
