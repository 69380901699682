
import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react';
import axios from 'axios';
import {
    Box,
    IconButton,
    TextField,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Fab,
    Divider,
    useMediaQuery,
    Button,
    Theme,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import './chatAssistance.css';
import { selectUser } from '../../Redux/Slices/userSlice';
import { useSelector } from '../../Redux/reduxHooks';
// import { saveVideoClientState } from '../../Redux/Slices/videoSlice';
import { getAuthHeader } from '../../Services/userService';
import { selectColors } from '../../Redux/Slices/generalSlice';


interface Message {
    text: string;
    sender: 'user' | 'support';
    timestamp: string;
    custom?: any;
}

const formatDate = (date: Date) => {
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();
    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    if (isToday) {
        return `Today, ${date.toLocaleTimeString(undefined, options)}`;
    } else {
        options.weekday = 'short';
        options.month = 'short';
        options.day = 'numeric';
        return `${date.toLocaleDateString(undefined, options)}, ${date.toLocaleTimeString(undefined, options)}`;
    }
};

const ChatAssistance: React.FC = () => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const hasGreetedRef = useRef(false); // Track if the greeting message was shown

    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [input, setInput] = useState('');
    const colors = useSelector(selectColors);
    const logInUser = useSelector(selectUser);

    const sendMessageToAPI = async (query: string) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/chat/installer`, {
                query,
            }, {
                headers: {
                    ...getAuthHeader()
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error communicating with the server:', error);
            return "Something went wrong, please try again later.";
        }
    };

    useEffect(() => {
        if (isOpen) {
            scrollToBottom();
        }
    }, [messages, isOpen]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const toggleChat = () => {
        setIsOpen((prev) => !prev);

        if (!hasGreetedRef.current && logInUser?.id) {
            // Add greeting message only once
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    text: "Hi, How can i help you today?",
                    sender: 'support',
                    timestamp: formatDate(new Date()),
                },
            ]);
            hasGreetedRef.current = true; // Mark greeting as shown
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value);
    };

    const handleSendMessage = async () => {
        if (input.trim() === '') return;

        const userMessage: Message = {
            text: input,
            sender: 'user',
            timestamp: formatDate(new Date()),
        };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInput('');

        try {
            const botResponse = await sendMessageToAPI(input);
            const botMessage: Message = {
                text: botResponse.data,
                sender: 'support',
                timestamp: formatDate(new Date()),
            };
            setMessages((prevMessages) => [...prevMessages, botMessage]);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    return (
        <Box
            className="chat-container"
            sx={{
                position: 'fixed',
                bottom: isMobile ? 0 : 16,
                right: isMobile ? 0 : 16,
                width: isMobile ? '100%' : 'auto',
                height: 'auto',
                justifyContent: isMobile ? 'end' : '',
            }}
        >
            {isOpen && (
                <Paper
                    elevation={3}
                    sx={{
                        width: isMobile ? '100%' : 350,
                        height: isMobile ? 'calc(100% - 50.8px - 12px)' : 'auto',
                        mb: 2,
                        borderRadius: isMobile ? 0 : 2,
                    }}
                >
                    <Box
                        className="chat-header"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 2,
                            bgcolor: 'primary.main',
                            color: 'white',
                            borderTopLeftRadius: isMobile ? 0 : 2,
                            borderTopRightRadius: isMobile ? 0 : 2,
                        }}
                    >
                        <Typography variant="h6" sx={{ padding: "5px" }}>Admin Assistance</Typography>
                        <IconButton size="small" sx={{ color: 'white' }} onClick={toggleChat}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        className="chat-body"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: isMobile ? 'calc(100vh - 112px)' : 400,
                        }}
                    >
                        <List className="messages" sx={{ flex: 1, overflowY: 'auto', p: 2 }}>
                            {messages.map((message, index) => (
                                <ListItem
                                    key={index}
                                    className={message.sender === 'user' ? 'message user' : 'message support'}
                                    sx={{
                                        alignItems: 'flex-start',
                                        justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        bgcolor: message.sender === 'user' ? colors.primaryLight : colors.lightGray,
                                        color: colors.text,
                                        borderRadius: 10,
                                        p: 8,
                                        m: 1,
                                        maxWidth: '70%',
                                        margin: '10px 0 0 10px ',
                                    }}
                                >
                                    <ListItemText primary={message.text} />
                                    <Typography variant="caption" sx={{ mt: 0.5, color: 'gray' }}>
                                        {message.timestamp}
                                    </Typography>
                                </ListItem>
                            ))}
                            <div ref={messagesEndRef} />
                        </List>

                        <Divider />
                        <Box
                            className="chat-input"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: 2,
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={input}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                placeholder="Type your message..."
                                sx={{ bgcolor: 'white', borderRadius: 1 }}
                            />
                            <IconButton onClick={handleSendMessage} color="primary">
                                <SendIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Paper>
            )}
            <Fab className="chat-btn" sx={{ width: '50.8px', height: '50.8px' }} color="primary" aria-label="chat" onClick={toggleChat}>
                <ChatIcon />
            </Fab>
        </Box>
    );
};


export default ChatAssistance;
