export const allRoutes = {
	HOME: "/",
	ACCOUNT_SETTINGS: "/account-settings",
	UPDATE_STATUS: "/customers/update-status/:id",
	DASHBOARD: "/dashboard",
	INSTALLER_COMPANIES: "/installers/companies",
	VIEW_INSTALLER_COMPANY: "/installers/companies/view/:id",
	EDIT_INSTALLER_COMPANY: "/installers/companies/edit/:id",
	ADD_INSTALLER_COMPANY: "/installers/companies/add-company",
	INSTALLER_ADMINS: "/installers/installer-admins",
	ADD_INSTALLER_ADMIN: "/installers/installer-admins/add-admin",
	VIEW_INSTALLER_ADMIN: "/installers/installer-admins/view/:id",
	VIEW_INSTALLER_ADMIN_DASHBOARD: "/installers/installer-admins/view-dashboard/:id",
	EDIT_INSTALLER_ADMIN: "/installers/installer-admins/edit/:id",
	OFFICE_MANAGERS: "/installers/office-managers",
	ADD_OFFICE_MANAGER: "/installers/office-managers/add-office-manager",
	VIEW_OFFICE_MANAGER: "/installers/office-managers/view/:id",
	VIEW_OFFICE_MANAGER_DASHBOARD: "/installers/office-managers/view-dashboard/:id",
	EDIT_OFFICE_MANAGER: "/installers/office-managers/edit/:id",
	MANAGERS: "/installers/managers",
	ADD_MANAGER: "/installers/managers/add-manager",
	VIEW_MANAGER: "/installers/managers/view/:id",
	VIEW_MANAGER_DASHBOARD: "/installers/managers/view-dashboard/:id",
	EDIT_MANAGER: "/installers/managers/edit/:id",
	REPRESENTATIVES: "/installers/representatives",
	ADD_REPRESENTATIVE: "/installers/representatives/add-representative",
	VIEW_REPRESENTATIVE: "/installers/representatives/view/:id",
	VIEW_REPRESENTATIVE_DASHBOARD: "/installers/representatives/view-dashboard/:id",
	EDIT_REPRESENTATIVE: "/installers/representatives/edit/:id",
	FAQS: "/faqs",
	ADD_FAQ: "/faqs/add-faq",
	EDIT_FAQ: "/faqs/edit/:id",
	DELETE_FAQ: "/faqs/delete/:id",
	CHAT: "/chat",
	DEFAULT_WELCOME_MSG_FOR_SUPPORT_CHAT: "/chat-config-msg",
	CSR: "/csr",
	CSR_CHAT: "/csr/chat",
	CSR_VIDEO_CHAT: "/csr/chat/video-chat",
	ACCEPT_SUPPORTCHAT_INVITE: "/accept-support-chat-invite",
	ADD_CSR: "/csr/add-csr",
	VIEW_CSR: "/csr/view/:id",
	EDIT_CSR: "/csr/edit/:id",

	ADMINS: "/admins",
	VIEW_ADMIN: "/admins/view/:id",
	VIEW_ADMIN_DASHBOARD: "/admins/view-dashboard/:id",
	EDIT_ADMIN: "/admins/edit/:id",
	ADD_ADMIN: "/admins/add-admin",
	CUSTOMERS: "/customers",
	TECHNICIANS: "/installation-crew",
	VIEW_TECHNICIAN: "/installation-crew/view/:id",
	EDIT_TECHNICIAN: "/installation-crew/edit/:id",
	ADD_TECHNICIAN: "/installation-crew/add-installation-crew",
	CALENDAR_TECHNICIAN: "/installation-crew/calendar/:id",

	CONFIGURATIONS: "/configurations",
	UTILITY_COMPANIES_PAGE: "/configurations/utility-companies",
	ADD_UTILITY_COMPANY: "/configurations/add-utility-company",
	EDIT_UTILITY_COMPANY: "/configurations/edit-utility-company/:id",
	VIEW_UTILITY_COMPANY: "/configurations/view-utility-company/:id",
	COLOR_THEME: "/configurations/color-theme",

	TEXT_SNIPPETS: "/text-snippets",
	VIEW_CUSTOMER: "/customers/view/:id",
	EDIT_CUSTOMER: "/customers/edit/:id",
	ADD_CUSTOMER: "/customers/add-customer",

	LOGS: "/customers/logs/:id",

	ESTIMATE: "/estimate",
	SOLAR_REPORT: "/solar-report",
	LOGIN: "/login",
	SIGN_UP: "/register",
	RESET_PASSWORD: "/reset-password/:userId?/:token?",
	VERIFY_EMAIL: "/auth/verify-email/:userId?/:token?",
	WHY_US: "/why-us",
	PROPOSAL_ACCEPTANCE: "/scheduling/proposal-acceptance",
	SITE_SURVEY: "/scheduling/site-survey",
	CAD_DESIGN: "/scheduling/cad-design",
	SCHEDULING: "/scheduling",
	PAYMENT: "/payment",
	ACCOUNT_CREATION: "/account-creation",
	AURORA_URL: "https://app.subcontractorhub.com/",
};
