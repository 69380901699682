import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/download-csv";

const DownloadCustomersCsvService = {
	downloadCustomersCsv: () =>
		http.get(`${apiEndpoint}`, {
			headers: getAuthHeader(),
			responseType: "blob",
		}),
	downloadFunnelDataCsv: () =>
		http.get(`${apiEndpoint}/download-funnelData-csv`, {
			headers: getAuthHeader(),
			responseType: "blob",
		}),
	downloadCustomersProposalCsv: (userId: string) =>
		http.get(`${apiEndpoint}/summary-proposal/${userId}`, {
			headers: getAuthHeader(),
			responseType: "blob",
		}),
};

// =====|  APIs  |=====

export const downloadCustomersCsv = () => {
	return DownloadCustomersCsvService.downloadCustomersCsv();
};
export const downloadFunnelDataCsv = () => {
	return DownloadCustomersCsvService.downloadFunnelDataCsv();
};
export const downloadCustomersProposalCsv = (userId: string) => {
	return DownloadCustomersCsvService.downloadCustomersProposalCsv(userId);
};
